import { Auth } from 'aws-amplify';
import PNotify from "pnotify/dist/es/PNotify";
import "pnotify/dist/es/PNotifyButtons";
import "pnotify/dist/es/PNotifyConfirm";
import "pnotify/dist/es/PNotifyCallbacks";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

if (typeof window.stackBottomRight === 'undefined') {
    window.stackBottomRight = {
        'dir1': 'up',
        'dir2': 'left',
        'firstpos1': 25,
        'firstpos2': 25
    };
}

const getToken = () => new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
        user.getSession((err, session) => {
            if (err) {
                alert('an unexpected error occured');
                console.log(err);
            } else {
                resolve(session.getIdToken().getJwtToken());
            }
        });
    }).catch(reject);
});

const getData = path => new Promise( resolve => {
    getToken().then(token => {
        axios.get(window.API_GATEWAY_ENDPOINT + path + '?id=all', {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('An unexpected error occured');
            console.log(err);
        });
    }).catch(err => {
        alert('An unexpected error occured');
        console.log(err);
    });
});

const getBillDetails = id => new Promise(resolve => {
    getToken().then(token => {
        axios.get(window.API_GATEWAY_ENDPOINT + '/bills?id=' + id, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('An unexpected error occured');
            console.log(err);
        });
    }).catch(err => {
        alert('An unexpected error occured');
        console.log(err);
    })
});

const putData = path => data => new Promise(resolve => {
    getToken().then(token => {
        axios.put(window.API_GATEWAY_ENDPOINT + path, data, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('An unexpected error occured');
            console.log(err);
        });
    }).catch(err => {
        alert('An unexpected error occured');
        console.log(err);
    })
});

const postData = path => data => new Promise(resolve => {
    getToken().then(token => {
        axios.post(window.API_GATEWAY_ENDPOINT + path, data, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('An unexpected error occured');
            console.log(err);
        });
    }).catch(err => {
        alert('An unexpected error occured');
        console.log(err);
    })
});

const deleteData = path => key => new Promise(resolve => {
    getToken().then(token => {
        axios.delete(window.API_GATEWAY_ENDPOINT + path + '?id=' + key, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('An unexpected error occured');
            console.log(err);
        });
    }).catch(err => {
        alert('An unexpected error occured');
        console.log(err);
    })
});

export default {
    getToken: getToken,
    getData: getData,
    postData: postData,
    putData: putData,
    deleteData: deleteData,
    getBillDetails: getBillDetails,

    capitalizeFirstLetter: s => s.charAt(0).toUpperCase() + s.slice(1),

    isAuthenticated: () => new Promise(resolve => {
        Auth.currentAuthenticatedUser().then(user => resolve(true)).catch(err => resolve(false));
    }),

    getFirstname: () => Auth.user.attributes['custom:firstname'],
    getLastname: () => Auth.user.attributes['custom:lastname'],
    getEmail: () => Auth.user.attributes.email,

    notify: PNotify,

    logout: () => new Promise(resolve => {
        Auth.signOut().then(resolve).catch(err => console.log(err));
    }),

    signIn: (email, password) => Auth.signIn({
        username: email,
        password: password
    }),

    inputProductsParams: callback => {
        let MySwal = withReactContent(Swal);
        MySwal.fire({
            text: 'Enter the product quantity',
            input: 'number',
            showCancelButton: true
        }).then((result) => {
            if (result.value) {
                if (parseInt(result.value) < 1) {
                    PNotify.error({
                        title: 'The quantity must be equal or greater than 1',
                        stack: window.stackBottomRight,
                        delay: 2500
                    });
                } else callback(parseInt(result.value));
            }
        });
    },

    deleteConfirm: callback => {
        let MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone',
            type: 'error',
            showCloseButton: true,
            showCancelButton: true
        }).then((willDelete) => {
            callback(willDelete.value);
        });
    }
}
